<template>
	<div>
		<update-password-form></update-password-form>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import UpdatePasswordForm from './forms/UpdatePasswordForm';

export default {

	name: 'ProfilePage',

	computed: mapGetters([ 'currentAccount' ]),

	created() {
		if (this.currentAccount.type !== 'STANDARD') {
			return this.$router.replace('/account/profile');
		}
	},

	components: {
		UpdatePasswordForm,
	},

};

</script>
