<template>
	<div class="form-wrapper page-has-nav small">
		<div class="form-container">
			<form action="/user/security" method="get" @submit.prevent="onSubmit">
				<form-errors :errors="errors"></form-errors>

				<div v-if="showSuccess" class="alert alert-good">
					<ul>
						<li><span class="fal fa-thumbs-up"></span>Password updated</li>
					</ul>
				</div>

				<field-errors v-if="submitAttempted" :form="$v.form" field="current_password"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Old Password</div>
					<div class="input-box"><input type="password" v-model="form.current_password" autocomplete="current-password"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="new_password"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">New Password</div>
					<div class="input-box"><input type="password" v-model="form.new_password" autocomplete="new-password"></div>
				</div>
				<field-errors v-if="submitAttempted" :form="$v.form" field="new_password_confirmation" name="New Password Confirmation"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Confirm New Password</div>
					<div class="input-box"><input type="password" v-model="form.new_password_confirmation" autocomplete="new-password"></div>
				</div>
				<submit-button text="Update Now" icon="fa-lock" :has-errors="hasErrors" :loading="formSaving"></submit-button>
			</form>
		</div>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {

	mixins: [ FormMixin ],

	data() {
		return {
			form: {},
			showSuccess: false,
		};
	},

	validations: {
		form: {
			current_password: { required },
			new_password: { required },
			new_password_confirmation: {
				required,
				sameAs: sameAs('new_password'),
			},
		}
	},

	methods: {

		onSubmit() {
			this.showSuccess = false;
			this.submit(async () => {
				await this.$api.post('/user/password', this.form);
				this.form = {};
				this.showSuccess = true;
				this.submitAttempted = false;
			});
		},

	},
};

</script>
